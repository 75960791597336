const middleware = {}

middleware['acl'] = require('../src/middleware/acl.ts')
middleware['acl'] = middleware['acl'].default || middleware['acl']

middleware['authenticated'] = require('../src/middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['notAuthenticated'] = require('../src/middleware/notAuthenticated.ts')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

export default middleware
