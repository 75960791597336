import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _49458f36 = () => interopDefault(import('../src/pages/install.vue' /* webpackChunkName: "pages/install" */))
const _0ab5813a = () => interopDefault(import('../src/pages/testOb.vue' /* webpackChunkName: "pages/testOb" */))
const _fce27b94 = () => interopDefault(import('../src/pages/admin/install.vue' /* webpackChunkName: "pages/admin/install" */))
const _297fc572 = () => interopDefault(import('../src/pages/admin/login/index.vue' /* webpackChunkName: "pages/admin/login/index" */))
const _7b84b03c = () => interopDefault(import('../src/pages/admin/pages/index.vue' /* webpackChunkName: "pages/admin/pages/index" */))
const _7e200aca = () => interopDefault(import('../src/pages/admin/settings/index.vue' /* webpackChunkName: "pages/admin/settings/index" */))
const _27c37db4 = () => interopDefault(import('../src/pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _2b00e6cc = () => interopDefault(import('../src/pages/admin/groups/new.vue' /* webpackChunkName: "pages/admin/groups/new" */))
const _b9a3e0a0 = () => interopDefault(import('../src/pages/admin/pages/new.vue' /* webpackChunkName: "pages/admin/pages/new" */))
const _142c29f4 = () => interopDefault(import('../src/pages/admin/users/new.vue' /* webpackChunkName: "pages/admin/users/new" */))
const _526c9098 = () => interopDefault(import('../src/pages/admin/groups/_id.vue' /* webpackChunkName: "pages/admin/groups/_id" */))
const _105c01cd = () => interopDefault(import('../src/pages/admin/pages/_id/index.vue' /* webpackChunkName: "pages/admin/pages/_id/index" */))
const _818d4d5a = () => interopDefault(import('../src/pages/admin/settings/_slug.vue' /* webpackChunkName: "pages/admin/settings/_slug" */))
const _0076550e = () => interopDefault(import('../src/pages/admin/users/_id.vue' /* webpackChunkName: "pages/admin/users/_id" */))
const _6c7c012b = () => interopDefault(import('../src/pages/admin/pages/_id/components.vue' /* webpackChunkName: "pages/admin/pages/_id/components" */))
const _80efa240 = () => interopDefault(import('../src/pages/admin/_entity/index.vue' /* webpackChunkName: "pages/admin/_entity/index" */))
const _16ca3e2e = () => interopDefault(import('../src/pages/admin/_entity/new.vue' /* webpackChunkName: "pages/admin/_entity/new" */))
const _03146948 = () => interopDefault(import('../src/pages/admin/_entity/_id.vue' /* webpackChunkName: "pages/admin/_entity/_id" */))
const _27eb63ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1abd2928 = () => interopDefault(import('../src/pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _1c0cd1b6 = () => interopDefault(import('../src/pages/_category/_slug/index.vue' /* webpackChunkName: "pages/_category/_slug/index" */))
const _2e4e05a4 = () => interopDefault(import('../src/pages/_category/_subcategory/_slug/index.vue' /* webpackChunkName: "pages/_category/_subcategory/_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/install",
    component: _49458f36,
    name: "install"
  }, {
    path: "/testOb",
    component: _0ab5813a,
    name: "testOb"
  }, {
    path: "/admin/install",
    component: _fce27b94,
    name: "admin-install"
  }, {
    path: "/admin/login",
    component: _297fc572,
    name: "admin-login"
  }, {
    path: "/admin/pages",
    component: _7b84b03c,
    name: "admin-pages"
  }, {
    path: "/admin/settings",
    component: _7e200aca,
    name: "admin-settings"
  }, {
    path: "/admin/users",
    component: _27c37db4,
    name: "admin-users"
  }, {
    path: "/admin/groups/new",
    component: _2b00e6cc,
    name: "admin-groups-new"
  }, {
    path: "/admin/pages/new",
    component: _b9a3e0a0,
    name: "admin-pages-new"
  }, {
    path: "/admin/users/new",
    component: _142c29f4,
    name: "admin-users-new"
  }, {
    path: "/admin/groups/:id?",
    component: _526c9098,
    name: "admin-groups-id"
  }, {
    path: "/admin/pages/:id",
    component: _105c01cd,
    name: "admin-pages-id"
  }, {
    path: "/admin/settings/:slug",
    component: _818d4d5a,
    name: "admin-settings-slug"
  }, {
    path: "/admin/users/:id",
    component: _0076550e,
    name: "admin-users-id"
  }, {
    path: "/admin/pages/:id/components",
    component: _6c7c012b,
    name: "admin-pages-id-components"
  }, {
    path: "/admin/:entity",
    component: _80efa240,
    name: "admin-entity"
  }, {
    path: "/admin/:entity?/new",
    component: _16ca3e2e,
    name: "admin-entity-new"
  }, {
    path: "/admin/:entity?/:id",
    component: _03146948,
    name: "admin-entity-id"
  }, {
    path: "/",
    component: _27eb63ad,
    name: "index"
  }, {
    path: "/:slug",
    component: _1abd2928,
    name: "slug"
  }, {
    path: "/:category/:slug",
    component: _1c0cd1b6,
    name: "category-slug"
  }, {
    path: "/:category/:subcategory?/:slug",
    component: _2e4e05a4,
    name: "category-subcategory-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
